import React, { useState, useEffect } from 'react';
import './Popup.css'; // Import the CSS styles

const FailurePopup = ({ message, onClose }) => {
    useEffect(() => {
      const timer = setTimeout(() => {
        onClose();
      }, 3000);
  
      return () => clearTimeout(timer);
    }, [onClose]);
  
    return (
      <div className="popup failure-popup">
        <div className="popup-content">
          <span className="popup-icon">❌</span>
          <p>{message || 'Something went wrong!'}</p>
        </div>
      </div>
    );
  };
  
  export default FailurePopup;
  