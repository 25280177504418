import React from 'react';
import trainImg from '../../../../assets/train.webp';
import busImg from '../../../../assets/bus.webp';
import planeImg from '../../../../assets/plane.webp';
import './Transportation.css';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { SpeedInsights } from "@vercel/speed-insights/react";

const Transportation = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.2 });

  React.useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.6 },
      });
    }
  }, [controls, inView]);

  return (
    <div className="transportation-section" ref={ref}>
      <SpeedInsights />
      {/* Transportation Icons */}
      <motion.div
        className="transportation-icons"
        initial={{ opacity: 0, y: 50 }}
        animate={controls}
      >
        <img src={trainImg} alt="Train" className="transport-image" />
        <img src={busImg} alt="Bus" className="transport-image" />
        <img src={planeImg} alt="Plane" className="transport-image" />
      </motion.div>

      {/* Transportation Description */}
      <motion.div
        className="transportation-description"
        initial={{ opacity: 0, y: 50 }}
        animate={controls}
      >
        <h2>Which transportation suits your journey?</h2>
        <p>
          Connecting users to Egypt's transportation network, the app offers
          access to buses, planes, and trains. Travelers can compare schedules,
          prices, and amenities for bus routes, book domestic flights, and check
          train schedules with ease, ensuring a smooth and efficient journey
          across the country.
        </p>
      </motion.div>
    </div>
  );
};

export default Transportation;
