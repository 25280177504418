import React, { useState, useEffect, useCallback, Suspense, lazy } from 'react';
import './Home.css';
import { Helmet } from "react-helmet-async";
import { SpeedInsights } from "@vercel/speed-insights/react";

const LogoAnimation = lazy(() => import('./LogoAnimation'));
const Landing = lazy(() => import('./Components/Landing/Landing'));
const WhatDrivesUs = lazy(() => import('./Components/WhatDrivesUs/WhatDrivesUs'));
const JoinUs = lazy(() => import('./Components/JoinUsAsAPartner/JoinUsAsAPartner'));
const ContactUsSection = lazy(() => import('./Components/ContactUsSection/ContactUsSection'));

const Home = () => {
  const [showAnimation, setShowAnimation] = useState(true);

  const preloadAssets = useCallback(async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulated delay
  }, []);

  useEffect(() => {
    preloadAssets().then(() => setShowAnimation(false));
  }, [preloadAssets]);

  const renderContent = () => (
    <div className="home-container">
      <div className="content">
          <Landing />
          <WhatDrivesUs />
          <JoinUs />
          <ContactUsSection />
      </div>
    </div>
  );

  return (
    <div className="Home">
      <SpeedInsights />
            <Helmet>
              
        <title>Trego Tech</title>
        <meta name="description" content="Welcome to Trego Tech! Explore our services and innovative solutions for technology and growth. Our upcoming flagship product, the Trego app, offers customers a streamlined platform to book trains, buses, and flights from various transport providers. Through our innovative API integrations, we deliver real-time options, empowering users to make smarter travel decisions." />
        <meta name="keywords" content="Trego Tech, technology, innovative solutions, tech growth" />
        <meta property="og:title" content="Trego Tech" />
        <meta property="og:description" content="Welcome to Trego Tech! Explore our services and innovative solutions for technology and growth. Our upcoming flagship product, the Trego app, offers customers a streamlined platform to book trains, buses, and flights from various transport providers. Through our innovative API integrations, we deliver real-time options, empowering users to make smarter travel decisions." />
        <meta property="og:url" content="https://www.tregotech.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      {showAnimation ? (
          <LogoAnimation onAnimationComplete={() => setShowAnimation(false)} />
       
      ) : (
        renderContent()
      )}
    </div>
  );
};

export default Home;
