import React, { useEffect, useState } from 'react';
import handWithMobile from '../../../../assets/handcomponent.webp'; // Middle hand with mobile image
import icon1 from '../../../../assets/handIcon1.webp'; // Icons for each text block
import icon2 from '../../../../assets/handIcon3.webp';
import icon3 from '../../../../assets/handIcon2.webp';
import icon4 from '../../../../assets/handIcon4.webp';
import './AccommodationComponent.css'; // Corresponding CSS

import { SpeedInsights } from "@vercel/speed-insights/react";
const AccommodationComponent = () => {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1024);
    };

    // Check on component mount
    handleResize();

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="accommodation-container">
      <SpeedInsights />
      {/* Title Section */}
      <div className="title-section">
        <h2>Find Your Perfect Stay with Ease.</h2>
      </div>

      {/* Content Section */}
      <div className="content-section">
        <div className="left-content">
          <div className="icon-text-block">
            <img src={icon1} alt="Icon" className="icon" />
            <div>
              <h3>Diverse Choices</h3>
              <p>
                Access a wide range of accommodations, from luxury hotels to cozy guesthouses, tailored to different budgets and preferences.
              </p>
            </div>
          </div>

          {/* Only render the second block on larger screens */}
          {!isMobileView && (
            <div className="icon-text-block">
              <img src={icon2} alt="Icon" className="icon" />
              <div>
                <h3>Seamless Booking</h3>
                <p>
                  Enjoy an easy and intuitive booking process directly through the app, with instant confirmation and secure payment options.
                </p>
              </div>
            </div>
          )}

          {/* Center Image appears below left content in mobile view */}
          {isMobileView && (
            <div className="center-image">
              <img src={handWithMobile} alt="Hand holding mobile" />
            </div>
          )}
        </div>

        {!isMobileView && (
          <div className="center-image">
            <img src={handWithMobile} alt="Hand holding mobile" />
          </div>
        )}

        <div className="right-content">
          {/* Third and fourth blocks always present */}
          <div className="icon-text-block">
            <img src={icon3} alt="Icon" className="icon" />
            <div>
              <h3>Exclusive Deals</h3>
              <p>
                Benefit from special offers through our strategic partnerships with the top accommodation providers.
              </p>
            </div>
          </div>

          <div className="icon-text-block">
            <img src={icon4} alt="Icon" className="icon" />
            <div>
              <h3>Personalized Recommendations</h3>
              <p>
                Receive tailored accommodation suggestions based on your travel preferences and previous stays.
              </p>
            </div>
          </div>

          {/* Render the additional icon block in the right content for mobile view */}
          {isMobileView && (
            <div className="icon-text-block additional-icon-block">
              <img src={icon2} alt="Icon" className="icon" />
              <div>
                <h3>Seamless Booking</h3>
                <p>
                  Enjoy an easy and intuitive booking process directly through the app, with instant confirmation and secure payment options.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccommodationComponent;
