import axios from 'axios';
import ApiConsts from './consts';
const ApiCaller = {

    sendRequest: async (email, firstName, lastName, description, onSuccess , onFailure) => {
        try {
            const response = await axios.post(`${ApiConsts.BASE_URL}${ApiConsts.PARTNERS}`, {
                email,
                firstName,
                lastName,
                description,
            },
          {   headers: {
                'Content-Type': 'application/json',
              }
        }
    );
            onSuccess(response.data);

        } catch (error) {
            onFailure(error);
        }
    },

    sendContact: async (email, firstName, lastName, description, reason , onSuccess , onFailure) => {
        try {
            const response = await axios.post(`${ApiConsts.BASE_URL}${ApiConsts.CONTACTUS}`, {
                email,
                firstName,
                lastName,
                description,
                reason,
            },
            {   headers: {
                'Content-Type': 'application/json',
              }
        }
    );
            console.log('Form Data Submitted:', response.data);
            onSuccess(response.data);
        } catch (error) {
            console.error('Error submitting form:', error);
            onFailure(error);
        }

    
    },

    subscribe: async (email, onSuccess , onFailure) => {
        try {
            const response = await axios.post(`${ApiConsts.BASE_URL}${ApiConsts.WAITINGLIST}`, {
                email,
            },
            {   headers: {
                'Content-Type': 'application/json',
              }
        }
    );
            onSuccess(response.data);
        } catch (error) {
            console.error('Error submitting form:', error);
            onFailure(error);
        }

  
    },
};

export default ApiCaller;
