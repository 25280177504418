import React from 'react';
import './Ultimate.css';
import trusted1 from '../../../../assets/halfCircle.webp';
import trusted2 from '../../../../assets/circle.webp';
import trusted3 from '../../../../assets/star.webp';
import trusted4 from '../../../../assets/stars.webp';
import walkthrough from '../../../../assets/Walkthrough illustration.webp';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { SpeedInsights } from "@vercel/speed-insights/react";

const Ultimate = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.2 });

  React.useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.6 },
      });
    }
  }, [controls, inView]);

  return (
    <div className="trego-container">
      <SpeedInsights />
      <section className="ultimate-section" ref={ref}>
        <motion.div
          className="ultimate-content"
          initial={{ opacity: 0, y: 100 }}
          animate={controls}
        >
          <h2>Ultimate Travel Companion</h2>
          <img
            src={walkthrough}
            alt="Travel companion image"
            className="ultimate-image"
          />
          <p>
            Travel effortlessly with a user-friendly platform for
            booking and managing your journeys. Enjoy seamless planning,
            real-time updates, and personalized itineraries for a smooth and
            enjoyable experience.
          </p>
        </motion.div>

        <motion.div
          className="cta-boxes"
          initial="hidden"
          animate={controls}
          variants={{
            visible: {
              transition: {
                staggerChildren: 0.2,
              },
            },
          }}
        >
          <motion.div className="cta-boxes">
            <motion.div className="cta-box" initial={{ opacity: 0, y: 50 }} animate={controls}>
              <img src={trusted1} alt="Trusted Icon" />
              <span> Trusted travel companion </span>
            </motion.div>
            <motion.div className="cta-box" initial={{ opacity: 0, y: 50 }} animate={controls}>
              <img src={trusted3} alt="Book with ease" />
              <span> Book with ease </span>
            </motion.div>
            <motion.div className="cta-box" initial={{ opacity: 0, y: 50 }} animate={controls}>
              <img src={trusted4} alt="Onboarding" />
              <span> Quick and easy onboarding </span>
            </motion.div>
            <motion.div className="cta-box hidden" initial={{ opacity: 0, y: 50 }} animate={controls}>
              <img src={trusted2} alt="Seamless travel" />
              <span> Seamless travel </span>
            </motion.div>
            <motion.div className="cta-box hidden" initial={{ opacity: 0, y: 50 }} animate={controls}>
              <img src={trusted1} alt="Discover more" />
              <span> Discover more </span>
            </motion.div>
          </motion.div>
        </motion.div>
      </section>
    </div>
  );
};

export default Ultimate;
