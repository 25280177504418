import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import NavLogo from '../assets/HeaderLogo.webp';
import AppLogo from '../assets/horizontal logo 2.webp';

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [newlySelectedLink, setNewlySelectedLink] = useState('');
  const location = useLocation();
  const navbarRef = useRef(null);

  const toggleNav = () => {
    setIsTransitioning(true);
    setIsNavOpen(!isNavOpen);
  };

  const closeNav = () => {
    setIsTransitioning(true);
    setIsNavOpen(false);
  };

  const handleLinkClick = (to) => {
    closeNav();
    setNewlySelectedLink(to);
    setTimeout(() => setNewlySelectedLink(''), 300); // Reset after animation
  };

  useEffect(() => {
    const transitionEndHandler = () => setIsTransitioning(false);
    const navbar = navbarRef.current;

    navbar.addEventListener('transitionend', transitionEndHandler);

    return () => {
      navbar.removeEventListener('transitionend', transitionEndHandler);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        closeNav();
      }
    };

    if (isNavOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isNavOpen]);

  // Scroll to the top whenever the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const isAppPage = location.pathname === '/app';
  const navbarStyle = isAppPage ? 'white-transparent' : 'black';
  const logoSrc = isAppPage ? AppLogo : NavLogo;

  return (
    <nav 
      ref={navbarRef} 
      className={`navbar ${navbarStyle} ${isNavOpen ? 'expanded' : ''} ${isTransitioning ? 'transitioning' : ''}`}
    >
      <div className="logo">
        <img src={logoSrc} alt="Tregotech Logo" />
      </div>
      <ul className={`nav-links ${isNavOpen ? 'active' : ''}`}>
        {[
          { to: "/", label: "Home" },
          { to: "/app", label: "Trego App" },
          { to: "/partner", label: "Become a Partner" },
          { to: "/about", label: "About Us" },
          { to: "/contact", label: "Contact Us" },
        ].map((link) => (
          <li key={link.to}>
            <Link 
              to={link.to} 
              onClick={() => handleLinkClick(link.to)}
              className={`${location.pathname === link.to ? 'active' : ''} ${newlySelectedLink === link.to ? 'newly-selected' : ''}`}
            >
              {link.label}
            </Link>
          </li>
        ))}
      </ul>
      {!isNavOpen ? (
        <button className="hamburger" onClick={toggleNav}>☰</button>
      ) : (
        <span className="close-btn" onClick={closeNav}>&times;</span>
      )}
    </nav>
  );
};

export default Navbar;
