import React from "react";
import './HowTo.css'
import how21 from '../../../assets/how2-1.webp'
import how22 from '../../../assets/how2-2.webp'
import how23 from '../../../assets/how2-3.webp'
import { SpeedInsights } from "@vercel/speed-insights/react";

const HowTo = () =>{

    return(
        <div>
            <SpeedInsights />
            <section className="how-to-partner-section">
                <h2>HOW TO BECOME A PARTNER?</h2>
                <div className="step-container">
                <div className="step-item">
                    <img className="main-image1" src={how21} alt="Step 1 Icon" />
                    <div className="step-content">
                    <span className="step-number">1</span>
                    <div className="step-text">
                        <h3>Get in touch</h3>
                        <p>Reach out to us via our contact form or email to express your interest in partnering with us. Provide a brief overview of your business and the type of partnership you're seeking.</p>
                    </div>
                    </div>
                </div>

                <div className="step-item">
                    <img className="main-image2" src={how23} alt="Step 2 Icon" />
                    <div className="step-content">
                    <span className="step-number">2</span>
                    <div className="step-text">
                        <h3>Discuss your needs</h3>
                        <p>Our team will set up a meeting to understand your needs and explore how our solutions can address them. We’ll discuss collaboration opportunities and tailor our approach to your goals.</p>
                    </div>
                    </div>
                </div>

                <div className="step-item">
                    <img className="main-image3" src={how22} alt="Step 3 Icon" />
                    <div className="step-content">
                    <span className="step-number">3</span>
                    <div className="step-text">
                        <h3>Formalize the partnership</h3>
                        <p>Once we’ve aligned on objectives and solutions, we’ll craft a partnership agreement outlining the scope, responsibilities, and benefits. After reviewing and signing the agreement, we’ll begin working together to bring your vision to life.</p>
                    </div>
                    </div>
                </div>
                </div>
            </section>
        </div>
    )
}

export default HowTo