import React from 'react';
import './TregoApp.css';
import Header from './Components/Header/Header';
import Ultimate from './Components/Ultimate/Ultimate';
import ImageSlider from './Components/mobilesSlide/ImageSlider'
import Transportation from './Components/Transportations/Transportation';
import AccommodationComponent from './Components/AccommodationComponent/AccommodationComponent';
import FAQComponent from './Components/Questions/FAQComponent';
import { Helmet } from "react-helmet-async";

import { SpeedInsights } from "@vercel/speed-insights/react";

const TregoApp = () => {
  return (
    <div className="trego-container">
      <SpeedInsights />
      <Helmet>
        <title >Trego App</title>
        <meta name="description" content="Trego App is a mobile application that provides transportation and accommodation services to users. We deliver real-time options, empowering users to make smarter travel decisions, with a focus on efficiency, convenience, and expanding partnerships" />
        <meta name="keywords" content="Trego App, transportation, accommodation, mobile application" />
        <meta property="og:title" content="Trego App" />
        <meta property="og:description" content="Trego App is a mobile application that provides transportation and accommodation services to users. We deliver real-time options, empowering users to make smarter travel decisions, with a focus on efficiency, convenience, and expanding partnerships" />
        <meta property="og:url" content="https://www.tregotech.com/app" />
        <meta property="og:type" content="website" />
      </Helmet>
      
        <Header/>
        <Ultimate/>
        <ImageSlider/>
        <Transportation/>
        <AccommodationComponent/>
        <FAQComponent/>
    </div>
  );
};

export default TregoApp;
