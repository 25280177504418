import React, { useState, useEffect, useRef } from "react";
import "./ImageSlider.css";
import mobile1 from '../../../../assets/mobile.webp';
import mobile2 from '../../../../assets/mobile2.webp';
import mobile3 from '../../../../assets/mobile3.webp';
import mobile4 from '../../../../assets/mobile4.webp';
import mobile5 from '../../../../assets/mobile5.webp';
import mobile6 from '../../../../assets/mobile1.webp';
import extra1 from '../../../../assets/goBus.webp';
import extra2 from '../../../../assets/BlueBus.webp';
import extra3 from '../../../../assets/booking.webp';
import extra4 from '../../../../assets/flyin.webp';

import { SpeedInsights } from "@vercel/speed-insights/react";

const ImageSlider = ({ images, type }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderWrapperRef = useRef(null);
  
    // Duplicate the images for smooth looping
    const extendedImages = [...images, ...images, ...images, ...images, ...images, ...images, ...images];
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentSlide((prevSlide) =>
          prevSlide === extendedImages.length - 1 ? 0 : prevSlide + 1
        );
      }, 3000); // Adjust the speed as needed
  
      return () => clearInterval(interval);
    }, [extendedImages.length]);
  
    return (
      <div className={`slider ${type === 'secondary' ? 'secondary-slider-container' : ''}`}>
        <SpeedInsights />
        <div
          className={`slider-wrapper ${type === 'secondary' ? 'secondary-slider-wrapper' : ''}`}
          ref={sliderWrapperRef}
          style={{
            transform: `translateX(-${currentSlide * (100 / extendedImages.length)}%)`,
            transition: 'transform 0.5s ease-in-out',
          }}
        >
          {extendedImages.map((img, index) => (
            <div
              className={`slide ${type === 'secondary' ? 'secondary-slide' : (index % 2 === 0 ? 'padding-top' : 'padding-bottom')}`}
              key={index}
            >
              <img src={img} alt={`Slide ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
    );
  };
  

const ImageSliderPage = () => {
  const firstSliderImages = [mobile1, mobile6, mobile4, mobile3, mobile2, mobile5];
  const secondSliderImages = [extra1, extra2, extra3, extra4];

  return (
    <div>
      {/* First Slider */}
      <ImageSlider images={firstSliderImages} />

      {/* Second Slider with images next to each other */}
      {/* <div style={{ backgroundColor: '#f1f0fe' }}>
        <ImageSlider images={secondSliderImages} type="secondary" />
      </div> */}

      {/* <div className="static-image-container">
        <img src={tregoMan} alt="Static Full Width" className="static-full-width-image" />
      </div> */}
    </div>
  );
};

export default ImageSliderPage;
