import React, { useState, useEffect } from 'react';
import './FAQs.css';
import FAQsimg from '../../../assets/FAQs.webp';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { SpeedInsights } from "@vercel/speed-insights/react";

const FAQItem = ({ question, answer, isOpen, onClick }) => {
  return (
    <div
      className={`faq-item ${isOpen ? 'open' : ''}`}
      onClick={onClick}
    >
      <SpeedInsights />
      <div className="faq-question">
        {question}
        <span className={`faq-toggle ${isOpen ? 'open' : ''}`}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 5V19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5 12H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </span>
      </div>
      <div className={`faq-answer ${isOpen ? 'open' : ''}`}>
        <div className="faq-answer-content">{answer}</div>
      </div>
    </div>
  );
};

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const faqData = [
    {
      question: "What services does Trego Tech offer?",
      answer: "Trego Tech is a software development company specializing in creating innovative solutions. Our flagship product, Trego, is a mobile application that helps users book trains, buses, and flights."
    },
    {
      question: "How can I partner with Trego Tech?",
      answer: "We'd love to collaborate. Simply reach out to us through our contact form on the website. We offer API integrations, software development, and other partnership opportunities to help grow your business."
    },
    {
      question: "Will Trego Tech release more products besides the Trego app?",
      answer: "Yes, Trego Tech plans to diversify its product offerings in the future. While the Trego app is our current focus, we are continuously exploring new ideas and solutions that can benefit various industries."
    },
    {
      question: "What is the vision of Trego Tech?",
      answer: "At Trego Tech, our vision is to create innovative software solutions that connect people and enhance their experiences across various industries."
    },
    {
      question: "What industries is Trego Tech planning to enter in the future?",
      answer: "While Trego Tech is initially focused on the travel industry, we are committed to exploring expansion into other industries."
    },
    {
      question: "How does Trego Tech ensure quality in its software products?",
      answer: "We prioritize quality at every stage of development at Trego Tech. Our team follows best practices in software engineering, including rigorous testing, code reviews, and continuous integration."
    }
  ];

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faqs-section">
      <img className="FAQs" src={FAQsimg} alt="FAQs" data-aos="fade-up" />
      <h2 data-aos="fade-up" data-aos-delay="100">Frequently Asked Questions</h2>
      <p data-aos="fade-up" data-aos-delay="200">
        Find questions and answers related to the design system, purchase, updates, and support.
      </p>
      <div className="faq-list">
        {faqData.map((item, index) => (
          <FAQItem
            key={index}
            question={item.question}
            answer={item.answer}
            isOpen={activeIndex === index}
            onClick={() => handleClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default FAQs;