import React from 'react';
import { BrowserRouter as DefaultRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import AboutUs from './pages/AboutUs/AboutUs.jsx';
import BecomeAPartner from './pages/BecomeAPartner/BecomeAPartner.jsx';
import Navbar from './components/Navbar.js';
import Footer from './components/Footer.js';
import ContactUs from './pages/ContactUs/Contactus.jsx';
import Home from './pages/Landing/Home.jsx';
import TregoApp from './pages/TregoApp/TregoApp.jsx';
import { HelmetProvider } from "react-helmet-async";

const App = ({ Router = DefaultRouter }) => {
  return (
    <HelmetProvider>
      <Router>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/partner" element={<BecomeAPartner />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/home" element={<Home />} />
            <Route path="/app" element={<TregoApp />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
};

export default App;
