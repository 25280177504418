import React from 'react';
import './header.css';
import appStore from '../../../../assets/Store download button.webp';
import googlePlay from '../../../../assets/Store download button1.webp';
import tregoPhone from '../../../../assets/mobile.webp';
import girlImage from '../../../../assets/girl.webp'; // Add the correct path to the girl image
import future from '../../../../assets/search.webp';
import future2 from '../../../../assets/futureboxIcon2.webp';
import future3 from '../../../../assets/futureboxIcon3.webp';
import transportaion from '../../../../assets/transportatin icon.webp'
import accommodation from '../../../../assets/Accomodation icon.webp'
import community from '../../../../assets/community icon.webp'
import './animation.css';
import SuccessPopup from '../../../../components/SuccessPopup';
import ErrorPopUp from '../../../../components/FailurePopup';
import { SpeedInsights } from "@vercel/speed-insights/react";
const Header = () =>{
    
    
    const [showSuccessPopup, setShowSuccessPopup] = React.useState(false);
    const [showErrorPopup, setShowErrorPopup] = React.useState(false);
    const handleOnButtonClick = () => {
        setShowErrorPopup(true);
    }


    return(
        <div className="trego-container">
            <SpeedInsights />
            <section className="intro-section">
            <h1>INTRODUCING TREGO APP: YOUR ULTIMATE TRAVEL COMPANION!</h1>
            <img src={girlImage} alt="Girl with bag and vase" className="girl-image" />
            </section>

            {/* Main Content Section */}
            <section className="main-content">
            <div className="text-content">
                <p className="features-header">Trego App: Many features all in one place</p>
                <ul className="features-list">
                <li>
                    <img className="icon-transportation" src={transportaion} alt="Transportation icon" /> 
                    <div className="features-text">
                    <h3>Transportation</h3>
                    <p>Integrating trains, buses, and flights into one easy-to-use platform</p>
                    </div>
                </li>
                <li>
                    <img className="icon-accommodation" src={accommodation} alt="Accommodation icon" />
                    <div className="features-text">
                    <h3>Accommodation</h3>
                    <p>Book a variety of accommodations directly through the app.</p>
                    </div>
                </li>
                <li>
                    <img className="icon-community" src={community} alt="Community icon" />
                    <div className="features-text">
                    <h3>Community</h3>
                    <p>Connect with fellow travelers, share tips, and gain insights to enrich your journey.</p>
                    </div>
                </li>
                </ul>

                <div className="store-buttons">
                <a onClick={handleOnButtonClick}><img src={appStore} alt="Download on the App Store" /></a>
                <a onClick={handleOnButtonClick}><img src={googlePlay} alt="Get it on Google Play" /></a>
                </div>
            </div>
            <div className="image-content">
                <img src={tregoPhone} alt="Trego App on Phone" />
                <div className="app-features">
                <div className="feature-box">
                    <img className='feature-icon' src={future} alt="Search Icon" />
                    <div className="feature-text">
                        <h3>Search</h3>
                        <p>Search for your wanted destination among a wide range of locations.</p>
                    </div>
                </div>
                <div className="feature-box">
                    <img className='feature-icon' src={future2} alt="Filter Icon" />
                    <div className="feature-text">
                    <h3>Compare</h3>
                    <p>Choose the fastest and cheapest trips from our transportation partnerships.</p>
                    </div>
                </div>
                <div className="feature-box">
                    <img className='feature-icon' src={future3} alt="Book Icon" />
                    <div className="feature-text">
                    <h3>Book</h3>
                    <p>Book your trips, wherever you are.</p>
                    </div>
                </div>
                </div>
            </div>
            </section>

            {showSuccessPopup && <SuccessPopup message="Success!" onClose={() => setShowSuccessPopup(false)} />}
      {showErrorPopup && <ErrorPopUp message="App not available yet!" onClose={() => setShowErrorPopup(false)} />}

            </div>
    )
}

export default Header;
