import React, { useState } from 'react';
import './ContactUs.css';
import SuccessPopup from '../../components/SuccessPopup.js';
import FailurePopup from '../../components/FailurePopup.js';
import ApiCaller from '../../api/handlers.js';
import { Helmet } from 'react-helmet-async';
import { SpeedInsights } from "@vercel/speed-insights/react";


const ContactUs = () => {
    const [formData, setFormData] = useState({
        email: '',
        subject: '',
        firstName: '',
        lastName: '',
        description: '',
        reason: '',
        message: ''
    });
    const [showSuccess, setShowSuccess] = useState(false);
    const [showFailure, setShowFailure] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        ApiCaller.sendContact(formData.email, formData.firstName, formData.lastName, formData.description, formData.reason, () => {
            setShowSuccess(true);
        }, () => {
            setShowFailure(true);
        });
    };

    return (
        <div className="contact-us-container2">
            <SpeedInsights />
            <Helmet>
                <title>Contact Us - Trego Tech</title>
                <meta name="description" content="Contact Trego Tech for any queries, partnerships, or support. We are here to help you grow." />
                <meta name="keywords" content="Trego Tech, contact us, partnerships, support, queries" />
                <meta property="og:title" content="Contact Us - Trego Tech" />
                <meta property="og:description" content="Contact Trego Tech for any queries, partnerships, or support. We are here to help you grow." />
                <meta property="og:url" content="https://www.tregotech.com/contact" />
                <meta property="og:type" content="website" />
            </Helmet>
            
            <div className="contact-us-content2">
                <h2>Contact us</h2>
                <p>The harder you work for something, the greater you'll feel when you achieve it.</p>
                
                <form onSubmit={handleSubmit} className="contact-form">
                    <div className="form-columns">
                        {/* First Column */}
                        <div className="form-column">
                            <input
                                type="email"
                                name="email"
                                required
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                            />

                            <div className="form-row">
                                <input
                                    type="text"
                                    name="firstName"
                                    required
                                    placeholder="First name"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                />
                                <input
                                    type="text"
                                    name="lastName"
                                    required
                                    placeholder="Last name"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                />
                            </div>

                            <input
                                type="text"
                                name="description"
                                placeholder="Description (optional)"
                                value={formData.description}
                                onChange={handleChange}
                            />

                            <input
                                type="text"
                                name="reason"
                                required
                                placeholder="Reason of contact"
                                value={formData.reason}
                                onChange={handleChange}
                            />
                        </div>
                        
                        <div className="form-column">
                            <select
                                name="subject"
                                value={formData.subject}
                                onChange={handleChange}
                                required
                            >
                                <option value="" disabled>Select a subject</option>
                                <option value="partnership">Partnership</option>
                                <option value="support">Support</option>
                                <option value="other">Other</option>
                                
                            </select>

                            <textarea
                                name="message"
                                placeholder="Message"
                                required
                                value={formData.message}
                                onChange={handleChange}
                            ></textarea>

                            <button type="submit" className="send-request-btn">
                                Send request
                            </button>
                        </div>
                    </div>
                </form>
                {showSuccess && <SuccessPopup message="Your request was submitted successfully!" onClose={() => setShowSuccess(false)} />}
                {showFailure && <FailurePopup message="There was an error submitting your request. Please try again." onClose={() => setShowFailure(false)} />}
            </div>
        </div>
    );
};

export default ContactUs;
