import React, { useState, useEffect } from 'react';
import './Popup.css'; // Import the CSS styles

const SuccessPopup = ({ message, onClose }) => {
  useEffect(() => {
    // Close the popup automatically after 3 seconds
    const timer = setTimeout(() => {
      onClose();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="popup success-popup">
      <div className="popup-content">
        <span className="popup-icon">✔️</span>
        <p 
        style={{color: 'white'}}
        >{message || 'Operation successful!'}</p>
      </div>
    </div>
  );
};

export default SuccessPopup;
