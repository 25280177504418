// BecomeAPartner.js
import React from 'react';
import './/BecomeAPartner.css';
import Hero from './Components/HeroSection';
import Info from './Components/InfoSection';
import How2 from './Components/HowTo';
import FAQs from './Components/FAQs';
import Contact from './Components/ContactUs';
import { Helmet } from 'react-helmet-async';
import { SpeedInsights } from "@vercel/speed-insights/react";

const BecomeAPartner = () => {
  return (
    <div className="partner-page">
      <SpeedInsights />
      <Helmet>
        <title>Become a Partner - Trego Tech</title>
        <meta name="description" content="Join Trego Tech as a partner and grow your business with our innovative solutions. Super charge your business by filling gaps with our cutting-edge tech soloutions, starting today" />
        <meta name="keywords" content="Trego Tech, partner, innovative solutions, business growth" />
        <meta property="og:title" content="Become a Partner - Trego Tech" />
        <meta property="og:description" content="oin Trego Tech as a partner and grow your business with our innovative solutions. Super charge your business by filling gaps with our cutting-edge tech soloutions, starting today" />
        <meta property="og:url" content="https://www.tregotech.com/partner" />
        <meta property="og:type" content="website" />
      </Helmet>
      {/* Hero Section */}
      <Hero></Hero>
      {/* Info Section */}
      <Info></Info>
      {/* How To Section*/}
      <How2></How2>
      {/* FAQs */}
      <FAQs></FAQs>
      {/* ContactUs */}
      <Contact></Contact>
    </div>
  );
};

export default BecomeAPartner;
